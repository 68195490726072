import React from "react";
import AirLawTable from "../components/table/AirLawTable"
import Box from '@mui/material/Box';
import { useUser } from "../components/authContextProvider/AuthContext";
import { Navigate } from "react-router-dom";

  
export default function Reporting() {

  const { user } = useUser();
  
  return (
    <div className="App">
      { user ? (<Box>
          <h1>Airline Customer Care Decision Support System</h1>
          <p>beta version for Airline service companies developed and provided by AirLaw.Pro</p>  
          <Box sx={{ width: '95%', typography: 'body1', margin: '30px' }}>
            <AirLawTable />
          </Box>
      </Box>) : (
        <Navigate replace to="/login" />
      )}
    </div>
  );
};
