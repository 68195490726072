import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import { AlignHorizontalCenter } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';


export default function ResetUserPwdDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [userEmail,setUserEmail] = React.useState(props.userEmail);
  const [userName,setUserName] = React.useState(props.userName);
  const [danielUrl, setDanielUrl] = useState('');
  const [danielToken, setDanielToken] = useState('');
  const navigate = useNavigate();  

  useEffect(() => {     
    setDanielUrl(process.env.REACT_APP_DANIEL_URL);
    setDanielToken(process.env.REACT_APP_DANIEL_TOKEN);    
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleResetPwd = () => {
    console.log(userEmail);    

    try{              
      if (userEmail != null)
      {               
          ///newUser/a36a1f51ef0a7428cfd1f7ab5745c6c92b77ba0a4f349396222451a340f3fb5c/sefara@kool7.com/aaa111/ccc/ddd/aaa
          var url = danielUrl +"/resetUserPassword/"+ danielToken +"/" +  userEmail;
          console.log(url);
          

          fetch(url)
          .then((res) => res.json())
          .then((data) => {         
            console.log(data);           
            setOpen(false);    
          })
      }
      else console.log('No email');       
    }
    catch (error) {
               console.log("You may be interested:");
               console.log(error);
           }
  };
//color="error" 
  return (
    <>
      <Button variant="contained" label="Delete" onClick={handleClickOpen}>
      <LockResetRoundedIcon/>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {userName}: Reset password requst
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">    
          <Box key="introBox"
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >      
          <Avatar key="infoIconAuthenticated" sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockResetRoundedIcon />
            </Avatar>
          <br />
            Click Agree to reset user password. Reset link will be delivered to the email: <b>{userEmail}</b>     <br /> Do you Agree?
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Back</Button>
          <Button onClick={handleResetPwd}>
            RESET
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}