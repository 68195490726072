import "../styles.css";
import React, { useRef, useLayoutEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { supabase } from "../lib/supabase";
import { useUser } from "../components/authContextProvider/AuthContext";
import NewUserDrawer from "../components/userManagement/NewUserDrawer";
import AllUsers from "../components/userManagement/allUsers";

const UserManagement = (props) => {
  const { user } = useUser();
  return (
    <div className="App">
      {user ? (<Box>
        <Box padding={3} />
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
             <h1>User management</h1>             
             <NewUserDrawer />
             <AllUsers />             
          </Grid>
        </Grid>
      </Box>
      ) : (
        <Navigate replace to="/login" />
      )}
    </div>
  )
}
export default UserManagement;