import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, requests, time, flights, baggage,details) {
  return { name, requests, time, flights, baggage,details };
}

const rows = [
  createData('Ivan Saratov', 434, 103, "12", "5", "4", "Details"),
  createData('Aliona Tarcenko', 237, 90, "18", "4","3", "Details"),
  createData('Stanislava Krulik', 272, 92, "15", "5.5","7", "Details"),
  createData('Sergii Kaluznii', 656, 120, "19", "4","4", "Details"),
  createData('Julia Zdanov', 122, 40, "33", "3","10", "Details"),
];

export default function AirLawTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Staff Name</StyledTableCell>
            <StyledTableCell align="right">Requests&nbsp;(amount)</StyledTableCell>
            <StyledTableCell align="right">Total Time&nbsp;(Hrs.)</StyledTableCell>
            <StyledTableCell align="right">1 response&nbsp;(avg. min.)</StyledTableCell>
            <StyledTableCell align="right">Responses per hour (avg.)</StyledTableCell>
            <StyledTableCell align="right">Responses per claim (avg.)</StyledTableCell>
            <StyledTableCell align="right">Audit log</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.requests}</StyledTableCell>
              <StyledTableCell align="right">{row.time}</StyledTableCell>
              <StyledTableCell align="right">{row.flights}</StyledTableCell>
              <StyledTableCell align="right">{row.baggage}</StyledTableCell>
              <StyledTableCell align="right">{row.details}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}