import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AdbIcon from '@mui/icons-material/Adb';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import MoneyOffCsredRoundedIcon from '@mui/icons-material/MoneyOffCsredRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import CurrencyPoundRoundedIcon from '@mui/icons-material/CurrencyPoundRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';

import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { red } from '@mui/material/colors';

function preventDefault(event) {
  event.preventDefault();
}

export default function DataBox2(props) {
  return (
    <React.Fragment>      
       <Grid container spacing={2}>
        <Grid item xs={12} md={4} container alignItems="center" justifyContent="center"> 
        { (props.flightEvaluationEngine.itSeemsItIsEligibleForEU261Compensation) && <EuroRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 60, mr: 2 }} />}
        { (!props.flightEvaluationEngine.itSeemsItIsEligibleForEU261Compensation) && (props.flightEvaluationEngine.itSeemsItIsEligibleForBritishCompensation) && <EuroRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 60, mr: 2 }} />}
        {/* <CurrencyPoundRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 60, mr: 2 }} />} */}
        { (!props.flightEvaluationEngine.itSeemsItIsEligibleForEU261Compensation) && (!props.flightEvaluationEngine.itSeemsItIsEligibleForBritishCompensation) && <MoneyOffCsredRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 60, mr: 2 }} />}
        </Grid>
        <Grid item xs={12} md={8}>
        {(props.flightEvaluationEngine.itSeemsItIsEligibleForEU261Compensation || props.flightEvaluationEngine.itSeemsItIsEligibleForBritishCompensation) && <h1>COMPENSATION</h1>}
        { (!props.flightEvaluationEngine.itSeemsItIsEligibleForEU261Compensation) && (!props.flightEvaluationEngine.itSeemsItIsEligibleForBritishCompensation) && <><h1>NO COMPENSATION</h1><h2>suggested</h2></>}
          <Typography component="p" variant="h4">
            { props.flightEvaluationEngine.itSeemsItIsEligibleForEU261Compensation && <>€</>} { props.flightEvaluationEngine.itSeemsItIsEligibleForEU261Compensation && props.flightEvaluationEngine.suggestedEU261CompensationSum}
            { (!props.flightEvaluationEngine.itSeemsItIsEligibleForEU261Compensation) && (props.flightEvaluationEngine.itSeemsItIsEligibleForBritishCompensation) && <>€</>}
            {/* <>£</>} */}
            { (!props.flightEvaluationEngine.itSeemsItIsEligibleForEU261Compensation) && (props.flightEvaluationEngine.itSeemsItIsEligibleForBritishCompensation) && props.flightEvaluationEngine.suggestedEU261CompensationSum}
          </Typography>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
          { props.flightEvaluationEngine.itSeemsItIsEligibleForEU261Compensation && <>based on EU261</>} 
          { ((!props.flightEvaluationEngine.itSeemsItIsEligibleForEU261Compensation) && (props.flightEvaluationEngine.itSeemsItIsEligibleForBritishCompensation) && (props.flightEvaluationEngine.suggestedCompensationSum != null)) && <>based on other regulation</> }               
          </Typography>
          <div>
            <Link color="primary" href={props.externalFlightVerificationLink} target="_blanc">
            {props.externalFlightVerificationText}
            </Link>
          </div>          
        </Grid>
      </Grid>
    </React.Fragment>
  );
}