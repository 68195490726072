import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import { useNavigate } from "react-router-dom";

import React, { useState, useEffect } from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {supabase } from "../../../lib/supabase";


export default function TagEditorPlugin(props) {
  console.log('TagEditorPlugin PROPS')
  console.log(props);  
  return useTagEditorComponent(props.user, props.templateId);
}

async function logActivity (userData, activity)
{
//  console.log(userData);
  var activityCorrelationID = '';
  if(sessionStorage.getItem('activityCorrelationID') == null) {
    console.log('activityCorrelationID returned NULL')
    sessionStorage.setItem('activityCorrelationID', uuid())
  }
  else{
    // console.log('activityCorrelationID returned value')
    // console.log(activityCorrelationID);
    // console.log( localStorage.getItem('activityCorrelationID'));

    activityCorrelationID = sessionStorage.getItem('activityCorrelationID');
    // console.log(activityCorrelationID);
  }

  const { data, error } = await supabase
    .from('logUserActivity')
    .insert([
      { user_email: userData.email, user_id: userData.id, type: activity, correlationID: activityCorrelationID },
    ])
}

function useTagEditorComponent(user, templateId) {
  console.log('I`m TagEditorPlugin ... ');  
  
  async function onCopyTextButtonClick() {
    console.log('I`m the CopyTextButton: DO NOT FORGET I WORKS ONLY ON PRODUCTION ');
    await logActivity (user, 'TemplateEdited');        
  }
    
  return (
    <>
      <OrderEditComponent templateId={templateId}  />
      <AirlineEditComponent templateId={templateId} /><br />
      <TagEditCanceledComponent  templateId={templateId} tagCategory={'canceled'}/><br />      
      <TagEditDelayComponent  templateId={templateId} tagCategory={'delay'}/><br />      
      <TagEditOtherComponent  templateId={templateId} tagCategory={'other'}/><br />     
    </>
  );
}

function OrderEditComponent ({templateId}) {
  // const initData = content;
  // const initData = {
  //   agentName: 'Oleksii Arestovych',
  //   compensationAmount: '400 €',
  //   flightNumber: 'W62375',
  //  };
 
  //TODO: FIX ME: (content error in database containing wrong name of objects in json guiConfig)
  //const myConfig = JSON.parse(guiConfig);
  // console.log('This is myConfig object');
  // console.log(myConfig);
  // console.log(myConfig.passengerName);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [orderState, setOrderState] = React.useState(JSON.parse(sessionStorage.getItem('responseOrderFilters')) || {
    '1st': false,
    '2nd': false,
    '3rd': false,
    '4th': false,
    '5th': false
  });    

  useEffect(() => {
    const onStorageChange = () => {
      setOrderState(JSON.parse(sessionStorage.getItem('responseOrderFilters')));
    };
    window.addEventListener('storage', onStorageChange);
    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, []);

  const handleOrderChange = async (event) => {
    
    console.log(event.target.name);
    console.log(event.target.checked);
    console.log(templateId);

    setOrderState({
      ...orderState,
      [event.target.name]: event.target.checked
    });

    if(event.target.checked)        
    {
      console.log('I`m trying to submit supabase order response TAG');
      console.log(event.target.name);
      console.log(templateId);

      const { data, error } = await supabase
      .from('responseOrders-templates')
      .insert([
        { 'responseOrderName': event.target.name, 'template': templateId },
      ])
    }
    else
    {      
      const { data: resultOfSelect, error } = await supabase     
        .from('responseOrders-templates')
        .select('id')
        .eq('template', templateId)
        .eq('responseOrderName', event.target.name);
      if (error) { 
        console.log(error);
      }
      else
      {
        
        const arrayOfIds = resultOfSelect.map(item => Object.values(item));
        console.log(arrayOfIds);

        const { data: resulltOfDelte, error } = await supabase
        .from('responseOrders-templates')
        .delete()
        .in('id', arrayOfIds);

        if (error) { 
          console.log(error);
        }
        else
        {
          console.log(resulltOfDelte);

        }
      }
    }

  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">Order</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={orderState['1st']}     
              onChange={handleOrderChange}           
              name="1st"
            />
          }
          label="1st"
        />
        <FormControlLabel
          control={
            <Switch
              checked={orderState['2nd']}              
              onChange={handleOrderChange}           

              name="2nd"
            />
          }
          label="2nd"
        />
          <FormControlLabel
          control={
            <Switch
              checked={orderState['3rd']}              
              onChange={handleOrderChange}           
              name="3rd"
            />
          }
          label="3rd"
        />
          <FormControlLabel
          control={
            <Switch
              checked={orderState['4th']}              
              onChange={handleOrderChange}           
              name="4th"
            />
          }
          label="4th"
        />
         <FormControlLabel
          control={
            <Switch
              checked={orderState['5th']}             
              onChange={handleOrderChange}            
              name="5th"
            />
          }
          label="5th"
        />       
      </FormGroup>
      <FormHelperText>Change is imminent</FormHelperText>
    </FormControl>
  );
}

function AirlineEditComponent ({templateId}) {
  // const initData = content;
  // const initData = {
  //   agentName: 'Oleksii Arestovych',
  //   compensationAmount: '400 €',
  //   flightNumber: 'W62375',
  //  };
 
  //TODO: FIX ME: (content error in database containing wrong name of objects in json guiConfig)
  //const myConfig = JSON.parse(guiConfig);
  // console.log('This is myConfig object');
  // console.log(myConfig);
  // console.log(myConfig.passengerName);
  const [airlineState, setAirlineState] = React.useState(JSON.parse(sessionStorage.getItem('airlineFilters')) );      

  useEffect(() => {
    const onStorageChange = () => {
      setAirlineState(JSON.parse(sessionStorage.getItem('airlineFilters')));
    };
    window.addEventListener('storage', onStorageChange);
    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, []);

  const handleAirlineChange = async (event) => {
    console.log(event.target.name);
    console.log(event.target.checked);
    console.log(templateId);

    setAirlineState({
      ...airlineState,
      [event.target.name]: event.target.checked
    });

    if(event.target.checked)        
    {
      console.log('I`m trying to submit supabase airlines-tamplates');
      console.log(event.target.name);
      const { data, error } = await supabase
      .from('airlines-tamplates')
      .insert([
        { 'airline': event.target.name, 'template': templateId },
      ])
    }
    else
    {      
      const { data: resultOfSelect, error } = await supabase     
        .from('airlines-tamplates')
        .select('id')
        .eq('template', templateId)
        .eq('airline', event.target.name);
      if (error) { 
        console.log(error);
      }
      else
      {
        
        const arrayOfIds = resultOfSelect.map(item => Object.values(item));
        console.log(arrayOfIds);

        const { data: resulltOfDelte, error } = await supabase
        .from('airlines-tamplates')
        .delete()
        .in('id', arrayOfIds);

        if (error) { 
          console.log(error);
        }
        else
        {
          console.log(resulltOfDelte);

        }
      }
    }
  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">Airline</FormLabel>
      <FormGroup>
      <FormControlLabel
          control={
            <Switch
              checked={airlineState['5W']}
              onChange={handleAirlineChange}
              name="5W"
            />
          }
          label="WAZ - 5W"
        />  
        <FormControlLabel
          control={
            <Switch
              checked={airlineState['W4']}
              onChange={handleAirlineChange}
              name="W4"
            />
          }
          label="WZ4 - W4"
        />  
        <FormControlLabel
          control={
            <Switch
              checked={airlineState['W6']}
              onChange={handleAirlineChange}
              name="W6"
            />
          }
          label="WZZ - W6"
        />
        <FormControlLabel
          control={
            <Switch
              checked={airlineState['W9']}
              onChange={handleAirlineChange}
              name="W9"
            />
          }
          label="WUK - W9"
        />
     
      </FormGroup>
      <FormHelperText>Change is imminent</FormHelperText>
    </FormControl>
  );
}

function TagEditCanceledComponent ({templateId, tagCategory}) {
  // const initData = content;
  // const initData = {
  //   agentName: 'Oleksii Arestovych',
  //   compensationAmount: '400 €',
  //   flightNumber: 'W62375',
  //  };
 
  //TODO: FIX ME: (content error in database containing wrong name of objects in json guiConfig)
  //const myConfig = JSON.parse(guiConfig);
  // console.log('This is myConfig object');
  // console.log(myConfig);
  // console.log(myConfig.passengerName);
  const [tagState, setTagState] = React.useState(JSON.parse(sessionStorage.getItem('tagCanceledFilters')) ||{
    'covid': false,
    'airportStrike': false,
    'notam': false,
    'weather': false,
    '2years': false,
    '14days': false,
    'payCompensation': false
  }); 

  useEffect(() => {
    const onStorageChange = () => {
      setTagState(JSON.parse(sessionStorage.getItem('tagCanceledFilters')));
    };
    window.addEventListener('storage', onStorageChange);
    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, []);

  const handleChange = async (event) => {
    console.log(event.target.name);
    console.log(event.target.checked);
    console.log(templateId);
    setTagState({
      ...tagState,
      [event.target.name]: event.target.checked
    });

    if(event.target.checked)        
    {
      console.log('I`m trying to submit supabase order response TAG');
      console.log(event.target.name);
      console.log(templateId);
      console.log(tagCategory);

      const { data, error } = await supabase
      .from('tags')
      .insert([
        { 'tagName': event.target.name, 'template': templateId, 'tagFlightStatus': tagCategory },
      ])
    }
    else
    {      
      const { data: resultOfSelect, error } = await supabase     
        .from('tags')
        .select('id')
        .eq('template', templateId)
        .eq('tagName', event.target.name)
        .eq('tagFlightStatus', tagCategory);

      if (error) { 
        console.log(error);
      }
      else
      {
        
        const arrayOfIds = resultOfSelect.map(item => Object.values(item));
        console.log(arrayOfIds);

        const { data: resulltOfDelte, error } = await supabase
        .from('tags')
        .delete()
        .in('id', arrayOfIds);

        if (error) { 
          console.log(error);
        }
        else
        {
          console.log(resulltOfDelte);

        }
      }
    }


  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">Tags: {tagCategory}</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={tagState.covid}
              onChange={handleChange}
              name="covid"
            />
          }
          label="COVID-19"
        />
        <FormControlLabel
          control={
            <Switch
              checked={tagState.airportStrike}
              onChange={handleChange}
              name="airportStrike"
            />
          }
          label="AIRPORT STRIKE"
        />
          <FormControlLabel
          control={
            <Switch
              checked={tagState['2years']}
              onChange={handleChange}
              name="2years"
            />
          }
          label="2 years rule"
        />
          <FormControlLabel
          control={
            <Switch
              checked={tagState['14days']}
              onChange={handleChange}
              name="14days"
            />
          }
          label="14 days rule"
        />
         <FormControlLabel
          control={
            <Switch
              checked={tagState.weather}
              onChange={handleChange}
              name="weather"
            />
          }
          label="WEATHER"
        />
        <FormControlLabel
          control={
            <Switch
              checked={tagState.notam}
              onChange={handleChange}
              name="notam"
            />
          }
          label="NOTAM"
        />        
        <FormControlLabel
          control={
            <Switch
              checked={tagState.payCompensation}
              onChange={handleChange}
              name="payCompensation"
            />
          }
          label="Compensation YES"
        />       
      </FormGroup>
      <FormHelperText>Change is imminent</FormHelperText>
    </FormControl>
  );
}

function TagEditDelayComponent ({templateId, tagCategory}) {
  // const initData = content;
  // const initData = {
  //   agentName: 'Oleksii Arestovych',
  //   compensationAmount: '400 €',
  //   flightNumber: 'W62375',
  //  };
 
  //TODO: FIX ME: (content error in database containing wrong name of objects in json guiConfig)
  //const myConfig = JSON.parse(guiConfig);
  // console.log('This is myConfig object');
  // console.log(myConfig);
  // console.log(myConfig.passengerName);
  const [tagState, setTagState] = React.useState(JSON.parse(sessionStorage.getItem('tagDelayFilters')) ||{
    'covid': false,
    'airportStrike': false,
    'notam': false,
    'weather': false,
    '2years': false,
    'delayLessThan3hours': false,
    '3hours': false,
    '5hours': false,
    'payCompensation': false
  }); 

  useEffect(() => {
    const onStorageChange = () => {
      setTagState(JSON.parse(sessionStorage.getItem('tagDelayFilters')));
    };
    window.addEventListener('storage', onStorageChange);
    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, []);

  const handleChange = async (event) => {
    console.log(event.target.name);
    console.log(event.target.checked);
    console.log(templateId);
    setTagState({
      ...tagState,
      [event.target.name]: event.target.checked
    });

    if(event.target.checked)        
    {
      console.log('I`m trying to submit supabase order response TAG');
      console.log(event.target.name);
      console.log(templateId);
      console.log(tagCategory);

      const { data, error } = await supabase
      .from('tags')
      .insert([
        { 'tagName': event.target.name, 'template': templateId, 'tagFlightStatus': tagCategory },
      ])
    }
    else
    {      
      const { data: resultOfSelect, error } = await supabase     
        .from('tags')
        .select('id')
        .eq('template', templateId)
        .eq('tagName', event.target.name)
        .eq('tagFlightStatus', tagCategory);

      if (error) { 
        console.log(error);
      }
      else
      {
        
        const arrayOfIds = resultOfSelect.map(item => Object.values(item));
        console.log(arrayOfIds);

        const { data: resulltOfDelte, error } = await supabase
        .from('tags')
        .delete()
        .in('id', arrayOfIds);

        if (error) { 
          console.log(error);
        }
        else
        {
          console.log(resulltOfDelte);

        }
      }
    }


  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">Tags: {tagCategory}</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={tagState.covid}
              onChange={handleChange}
              name="covid"
            />
          }
          label="COVID-19"
        />
        <FormControlLabel
          control={
            <Switch
              checked={tagState.airportStrike}
              onChange={handleChange}
              name="airportStrike"
            />
          }
          label="AIRPORT STRIKE"
        />
          <FormControlLabel
          control={
            <Switch
              checked={tagState['2years']}
              onChange={handleChange}
              name="2years"
            />
          }
          label="2 years rule"
        />
         <FormControlLabel
          control={
            <Switch
              checked={tagState.weather}
              onChange={handleChange}
              name="weather"
            />
          }
          label="WEATHER"
        />
        <FormControlLabel
          control={
            <Switch
              checked={tagState.notam}
              onChange={handleChange}
              name="notam"
            />
          }
          label="NOTAM"
        />
         <FormControlLabel
          control={
            <Switch
              checked={tagState.delayLessThan3hours}
              onChange={handleChange}
              name="delayLessThan3hours"
            />
          }
          label="Delay less than 3 hours"
        />
        <FormControlLabel
          control={
            <Switch
              checked={tagState['3hours']}
              onChange={handleChange}
              name="3hours"
            />
          }
          label="Delay departure 3 hours+"
        />
        <FormControlLabel
          control={
            <Switch
              checked={tagState['5hours']}
              onChange={handleChange}
              name="5hours"
            />
          }
          label="Delay departure 5 hours+"
        />
        <FormControlLabel
          control={
            <Switch
              checked={tagState.payCompensation}
              onChange={handleChange}
              name="payCompensation"
            />
          }
          label="Compensation YES"
        />      
      </FormGroup>
      <FormHelperText>Change is imminent</FormHelperText>
    </FormControl>
  );
}

function TagEditOtherComponent ({templateId, tagCategory}) {
  // const initData = content;
  // const initData = {
  //   agentName: 'Oleksii Arestovych',
  //   compensationAmount: '400 €',
  //   flightNumber: 'W62375',
  //  };
 
  //TODO: FIX ME: (content error in database containing wrong name of objects in json guiConfig)
  //const myConfig = JSON.parse(guiConfig);
  // console.log('This is myConfig object');
  // console.log(myConfig);
  // console.log(myConfig.passengerName);
  const [tagState, setTagState] = React.useState(JSON.parse(sessionStorage.getItem('tagOtherFilters')) ||{
    'covid': false,
    'airportStrike': false,
    'notam': false,
    'weather': false,
    '2years': false,
    'diverted':false,
    'showAllways': false
  }); 

  useEffect(() => {
    const onStorageChange = () => {
      setTagState(JSON.parse(sessionStorage.getItem('tagOtherFilters')));
    };
    window.addEventListener('storage', onStorageChange);
    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, []);

  const handleChange = async (event) => {
    console.log(event.target.name);
    console.log(event.target.checked);
    console.log(templateId);
    setTagState({
      ...tagState,
      [event.target.name]: event.target.checked
    });

    if(event.target.checked)        
    {
      console.log('I`m trying to submit supabase order response TAG');
      console.log(event.target.name);
      console.log(templateId);
      console.log(tagCategory);

      const { data, error } = await supabase
      .from('tags')
      .insert([
        { 'tagName': event.target.name, 'template': templateId, 'tagFlightStatus': tagCategory }
      ]);
      if (error) { 
        console.log(error);
      }
      else
      {
        console.log('result of writing tag to db:', data);
      }
    }
    else
    {      
      const { data: resultOfSelect, error } = await supabase     
        .from('tags')
        .select('id')
        .eq('template', templateId)
        .eq('tagName', event.target.name)
        .eq('tagFlightStatus', tagCategory);

      if (error) { 
        console.log(error);
      }
      else
      {
        
        const arrayOfIds = resultOfSelect.map(item => Object.values(item));
        console.log(arrayOfIds);

        const { data: resulltOfDelte, error } = await supabase
        .from('tags')
        .delete()
        .in('id', arrayOfIds);

        if (error) { 
          console.log(error);
        }
        else
        {
          console.log(resulltOfDelte);

        }
      }
    }


  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">Tags: {tagCategory}</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={tagState.covid}
              onChange={handleChange}
              name="covid"
            />
          }
          label="COVID-19"
        />
        <FormControlLabel
          control={
            <Switch
              checked={tagState.airportStrike}
              onChange={handleChange}
              name="airportStrike"
            />
          }
          label="AIRPORT STRIKE"
        />
          <FormControlLabel
          control={
            <Switch
              checked={tagState['2years']}
              onChange={handleChange}
              name="2years"
            />
          }
          label="2 years rule"
        />
        <FormControlLabel
          control={
            <Switch
              checked={tagState['diverted']}
              onChange={handleChange}
              name="diverted"
            />
          }
          label="diverted"
        />   
         <FormControlLabel
          control={
            <Switch
              checked={tagState.weather}
              onChange={handleChange}
              name="weather"
            />
          }
          label="WEATHER"
        />
        <FormControlLabel
          control={
            <Switch
              checked={tagState.notam}
              onChange={handleChange}
              name="notam"
            />
          }
          label="NOTAM"
        />  
        <FormControlLabel
          control={
            <Switch
              checked={tagState.showAllways}
              onChange={handleChange}
              name="showAllways"
            />
          }
          label="Show IF nothing else applicable"
        />
      </FormGroup>
      <FormHelperText>Change is imminent</FormHelperText>
    </FormControl>
  );
}