import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

export default function Abaut() {


  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://airlaw.pro/">
          AirLaw.Pro
        </Link>{' '}
        {new Date().getFullYear()}
        {'. All Rights Reserved'}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '91vh',
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
        <Typography variant="h2" component="h1" gutterBottom>
          Password reset
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          {'to EMANUEL Decision Support System'}
        </Typography>
        <Typography variant="body1">Password reset link was send to your email address.<br />
          <br /> Please go to your inbox and, follow instruction in the email. (sometimes you can find it in a spam folder). Click the link in your mailbox to set up your new password.</Typography>
      </Container>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="100vh">

          <Box style={{ padding: '0px', background: '#FFF', textAlign: 'center' }}>
            <Typography variant="body1">
              <span>
                <img src="../../images/partnership.png" alt="AirLaw.Pro" height="80" style={{ margin: '18px' }} />
              </span>
            </Typography>
          </Box>
          <Copyright />
        </Container>
      </Box>
    </Box>



    // <div className="App">
    //   <h1>Airline Customer Care Decision Support System</h1>
    //   <p>beta version for Airline service companies developed and provided by AirLaw.Pro</p>  
    //   <p>AirLaw.Pro evaluates global flight data in real time to identify disrupted flights<br /> and provide best possible customer service for air passengers in need.</p>        
    //   <div>
    //       <img src="../../images/airlawpro_logo.png" alt="AirLaw.Pro" height="50" style={{margin:'18px'}}/>
    //     </div>
    //   <p>Contact us:</p>
    //   <p>office@airlaw.pro<br/><a href="https://airlaw.pro">www.airlaw.pro</a></p>
    //   <p>+421-948-566549</p>      
    //   <div style={{padding:'50px', margin:'100px 0px 0px 0px', background:'#FFF'}}>
    //     <span>
    //       <img src="../../images/partners/1.webp" alt="AirLaw.Pro" height="80" style={{margin:'18px'}}/>
    //       <img src="../../images/partners/2.webp" alt="AirLaw.Pro" height="80" style={{margin:'18px'}}/>
    //       <img src="../../images/partners/3.webp" alt="AirLaw.Pro" height="80" style={{margin:'18px'}}/>
    //       <img src="../../images/partners/4.webp" alt="AirLaw.Pro" height="80" style={{margin:'18px'}}/>
    //       <img src="../../images/partners/5.webp" alt="AirLaw.Pro" height="80" style={{margin:'18px'}}/>
    //       <img src="../../images/partners/6.webp" alt="AirLaw.Pro" height="80" style={{margin:'18px'}}/>
    //     </span>        
    //   </div>
    // </div>    
  );
};
