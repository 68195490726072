import "../styles.css";
import React, { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/system";
import { supabase } from "../lib/supabase";
import { useUser } from "../components/authContextProvider/AuthContext";
import EditorManager from "../components/editor/EditorManager";


  const Templatemanager = (props) => {
    const { user } = useUser();
//    const emptyTemplateContent = '{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"toto je uplne namakany template 3 zo stringu","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}';
    const queryParameters = new URLSearchParams(window.location.search)
    const templateId = queryParameters.get("templateId")
    const templateLanguage = queryParameters.get("lang")
    const [show, setShow] = React.useState(false)

    useEffect(() => {
      const timeout = setTimeout(() => {
        setShow(true)
      }, 1000)
  
      return () => clearTimeout(timeout)
  
    }, [show])
  
    if (!show) return null
  
    return (
      <div className="App">
        {user ? (<Box>
          <Box padding={3} />
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <EditorManager user={user} templateId={templateId} templateLanguage={templateLanguage} />
            </Grid>
          </Grid>
        </Box>
        ) : (
          <Navigate replace to="/login" />
        )}
      </div>
    )
  }
export default Templatemanager;