import React, { useEffect, useState } from 'react';
import {supabase} from '../../lib/supabase';
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import Typography from '@mui/material/Typography';
import { InputLabel, NativeSelect, Select } from '@mui/material';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useUser } from '../authContextProvider/AuthContext';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import Alert from '@mui/material/Alert';


export default function UserNew(props) {
  
  const [emanuelUser, setEmanuelUser] = useState(JSON.parse(localStorage.getItem("emanuelUser")) || null); 
  const [userName, setUserName] = useState(emanuelUser?.full_name || null);
  const [secondaryEmail, setSecondaryEmail] = useState(emanuelUser?.secondary_email || null);
  const [phoneNumber, setPhoneNumber] = useState(emanuelUser?.phone_number || null);
  const [danielUrl, setDanielUrl] = useState('');
  const [danielToken, setDanielToken] = useState('');
  const [logoUrl, setLogoUrl] = useState('');  
  const [userEmail, setUserEmail] = useState('');  
  const { user } = useUser();

  const navigate = useNavigate(); 

   useEffect(() => {
      setUserEmail(user?.email);      
     // setEmanuelUser(JSON.parse(localStorage.getItem("emanuelUser")));
      setLogoUrl(process.env.REACT_APP_LOGO_URL);
      setDanielUrl(process.env.REACT_APP_DANIEL_URL);
      setDanielToken(process.env.REACT_APP_DANIEL_TOKEN);
      console.log("emanuel user: ",emanuelUser);
  }, []);

  async function logActivity (userData, activity)
  {
    console.log(userData);
    //const correlationID = uuid();
    
    const { data, error } = await supabase
      .from('logUserActivity')
      .insert([
        { user_email: userData.email, user_id: userData.id, type: activity, correlationID: null },
      ])
  }

   function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://airlaw.pro/">
          AirLaw.Pro
        </Link>{' '}
        {new Date().getFullYear()}
        {'. All Rights Reserved'}
      </Typography>
    );
   }

   const theme = createTheme();

   async function createEmanuelUser(supabaseUserId, myusername, myphonenumber, mysecondaryemail, myemail, myisowner, myismanager)
   {
    console.log('I`m create Emanuel User function')
    if(supabaseUserId != null)
    {
      const { data: d2, error: e2 } = await supabase.from('emanuelUsers').insert({ full_name:  myusername,  phone_number: myphonenumber, secondary_email: mysecondaryemail,  email: myemail, supabase_user: supabaseUserId, is_owner: myisowner, is_manager: myismanager});   
      if (e2)
      {  
        console.log('Error Create Emanuel User Function:',e2);
        throw e2;          
      }
      console.log('success:',d2);
      return true;      
    }
   }
   async function handleSubmit (event) {
      try{
        event.preventDefault();
        
        var supabaseUserId = null;
        var isMgr = false;
        const form_data = new FormData(event.currentTarget);
        console.log("formdata:", form_data);
        console.log("email:", form_data.get('email'));        
        console.log("defaultpassword:", form_data.get('defaultpassword'));        
        console.log("is_manager:", form_data.get('isManager'));
        console.log('username:', form_data.get('username'));
        const dpwd = form_data.get('defaultpassword');
        if ((dpwd != undefined) && (dpwd != null) && (dpwd.length > 5))
        {
        
            if(form_data.get('isManager') == "on") isMgr = true;
            ///newUser/a36a1f51ef0a7428cfd1f7ab5745c6c92b77ba0a4f349396222451a340f3fb5c/sefara@kool7.com/aaa111/ccc/ddd/aaa
            var url = danielUrl +"/newUser/"+ danielToken +"/" +  form_data.get('email') + "/" + form_data.get('defaultpassword');
            console.log(url);
            

            fetch(url)
            .then((res) => res.json())
            .then((data) => {         
              console.log(data);
              console.log("step2:");  
              var response1 = JSON.parse(data);
              console.log("metadata:", response1.Identities[0].Id);
              supabaseUserId =  response1.Identities[0].Id;    
              console.log("superuserId", supabaseUserId);
              const succesfullyCreatedUser = createEmanuelUser(supabaseUserId, form_data.get('username'),  form_data.get('phonenumber'),  form_data.get('secondaryemail'),  form_data.get('email'), false, isMgr);
              if (succesfullyCreatedUser) 
              {
                console.log('I`m In I should close props ');
                {props.closeDrawer(false)}
              }

            })
            .catch((error) => {
              console.error(error);              
              switchLoading(false);
              alert(error);
            });
            // const { data, error } = await supabase.auth.admin.createUser({ /////volaj namiesto create user v admin view https://localhost:7051/newUser/a36a1f51ef0a7428cfd1f7ab5745c6c92b77ba0a4f349396222451a340f3fb5c/sefara@kool7.com/aaa111/ccc/ddd/aaa 
            //   email: form_data.get('username'),
            //   password: ,
            //   email_confirm: true
            // })
            // if (error)
            // {  
            //   console.log(error);
            //    throw error;          
            // }
            // console.log('success created user - userdata:',data);
        }
        else alert('Change Password: Password minimum lenght is 6');       
      }
      catch (error) {
                 console.log("You may be interested:");
                 console.log(error);
             }
    };  

    return (
      <ThemeProvider theme={theme}>
      <Container key="introContainer" component="main" maxWidth="xs">
        <CssBaseline />
        <Box key="introBox"
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          {user ? (
           <>
            <Avatar key="infoIconAuthenticated" sx={{ m: 1, bgcolor: 'primary.main' }}>
              <PersonAddAltRoundedIcon />
            </Avatar>
            <Box key="formbox" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>           
            <TextField
              margin="normal"
              required
              fullWidth
              key="email"
              id="email"
              label="email"
              name="email"
              autoComplete="email"               
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              key="defaultpassword"
              id="defaultpassword"
              label="First Password"
              name="defaultpassword"
              autoComplete="defaultpassword"     
              min={6}       
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              key="usernametextfield"
              id="username"
              label="Full Name"
              name="username"
              autoComplete="username"               
              autoFocus
            />
            <TextField
              margin="normal"              
              fullWidth
              key="phonenumbertextfield"
              id="phonenumber"
              label="Phone number"
              name="phonenumber"
              autoComplete="phone"
              autoFocus              
            />
            <TextField
              margin="normal"              
              fullWidth
              key="secondaryemail"
              name="secondaryemail"
              label="Secondary Email"              
              id="secondaryemail"
              autoComplete="secondaryemail"              
            />
            <label id="label1"> User UI inteface:  </label>
            <NativeSelect
              disabled              
              defaultValue='EN'
              //onChange={handleLanugageChange}
              inputProps={{
                name: 'full-language-name',
                id: 'iso2',
              }}
            >
              <option value={'EN'}>English</option>
              <option value={'PL'}>Polish</option>
              <option value={'UA'}>Ukrainian</option>
              <option value={'DE'}>German</option>
              <option value={'ES'}>Spanish</option>
            </NativeSelect>         
            {emanuelUser.is_owner && <label id="label2" value="1">  Manager rights:  </label>}
            {emanuelUser.is_owner && <Checkbox id='isManager' name='isManager' defaultChecked={false} />}

            <Button
              key="sumitbutton"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={props.closeDrawer(false)}
            >
              Create account
            </Button>           
          </Box>   
          <Button onClick={props.closeDrawer(false)}>Close</Button>         
          </>
         ) : (
          <Navigate replace to="/login" />
          )}
        </Box>
        <Copyright key="copyrightBlock" sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    );
};
