import React, { useEffect, useState } from 'react';
import {supabase} from '../../lib/supabase';
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import Typography from '@mui/material/Typography';
import { InputLabel, NativeSelect, Select } from '@mui/material';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useUser } from '../authContextProvider/AuthContext';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import Alert from '@mui/material/Alert';



export default function UserEdit(props) {
  
  const [emanuelUser, setEmanuelUser] = useState(props.emanuelUser || null);  
  const [userName, setUserName] = useState(emanuelUser?.full_name || null);
  const [secondaryEmail, setSecondaryEmail] = useState(emanuelUser?.secondary_email || null);
  const [phoneNumber, setPhoneNumber] = useState(emanuelUser?.phone_number || null);
  const [userEmail, setUserEmail] = useState(emanuelUser?.email || null);  
  const [isManager, setIsManager] = useState(emanuelUser?.is_manager || null);  
  const [resultOfUpdateVisualize, setResultOfUpdateVisualize] = useState(false);  
  const [supabaseUserId, setSupabaseUserId]= useState(emanuelUser?.supabase_user || null);

  const [myPersonalEmanuelUserData, setMyPersonalEmanuelUserData] = useState(JSON.parse(localStorage.getItem("emanuelUser")) || null); 
  const { user } = useUser();

  const navigate = useNavigate(); 


  useEffect(() => {
    setTimeout(() => {
      setResultOfUpdateVisualize(false);    
  }, 10000);
    
}, [resultOfUpdateVisualize]);

   function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://airlaw.pro/">
          AirLaw.Pro
        </Link>{' '}
        {new Date().getFullYear()}
        {'. All Rights Reserved'}
      </Typography>
    );
   }
   
   const theme = createTheme();   

   const handleManagerChange = () => { 
    
    setIsManager(!isManager); 
    
  }; 

   async function handleSubmit (event) {
      try{
        event.preventDefault();
        
        const form_data = new FormData(event.currentTarget);
        console.log("formdata:", form_data);        
        console.log('username:', form_data.get('username'));

        const { data, error } = await supabase
        .from('emanuelUsers')
        .update({ full_name:  form_data.get('username'),  phone_number: form_data.get('phonenumber'),  secondary_email: form_data.get('secondaryemail'),  is_manager: isManager })
        .eq('supabase_user', supabaseUserId )
        .select()
        .single();
        
        if (error)
        {  
          console.log(error);
           throw error;          
        }
        console.log(data);
        if(data != null)
        {
          setResultOfUpdateVisualize(true);
          console.log('data:',data);
          console.log('user:',user);
          if (data.supabase_user == user.id)
          {
            localStorage.removeItem('emanuelUser');
            localStorage.setItem('emanuelUser', JSON.stringify(data));
          }
        }
        
      }
      catch (error) {
                 console.log("You may be interested:");
                 console.log(error);
             }
    };  

    return (
      <ThemeProvider theme={theme}>
      <Container key="introContainer" component="main" maxWidth="xs">
        <CssBaseline />
        <Box key="introBox"
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >


            <Avatar key="infoIconAuthenticated" sx={{ m: 1, bgcolor: 'primary.main' }}>
              <Person2RoundedIcon />
            </Avatar>
            <Box key="formbox" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>           
            {(props.closeDrawer == null) && (resultOfUpdateVisualize) && <Alert severity="success">Profile was updated successfully!</Alert> }
            <TextField
              margin="normal"
              required
              fullWidth
              key="usernametextfield"
              id="username"
              label="Full Name"
              name="username"
              autoComplete="username" 
              defaultValue={ emanuelUser?.full_name }
              autoFocus
            />
            <TextField
              margin="normal"              
              fullWidth
              key="phonenumbertextfield"
              id="phonenumber"
              label="Phone number"
              name="phonenumber"
              autoComplete="phone"
              autoFocus
              defaultValue={ emanuelUser?.phone_number }
            />
            <TextField
              margin="normal"              
              fullWidth
              key="secondaryemail"
              name="secondaryemail"
              label="Secondary Email"              
              id="secondaryemail"
              autoComplete="secondaryemail"
              defaultValue={ emanuelUser?.secondary_email }
            />
            <label id="label1"> User UI inteface:  </label>
            <NativeSelect
              disabled              
              defaultValue='EN'
              //onChange={handleLanugageChange}
              inputProps={{
                name: 'full-language-name',
                id: 'iso2',
              }}
            >
              <option value={'EN'}>English</option>
              <option value={'PL'}>Polish</option>
              <option value={'UA'}>Ukrainian</option>
              <option value={'DE'}>German</option>
              <option value={'ES'}>Spanish</option>
            </NativeSelect>         
            <label id="label2" value="1">  Manager:  </label>
            {myPersonalEmanuelUserData.is_owner && <Checkbox id='isManager' name='isManager' checked={isManager} onClick={handleManagerChange} /> }
            {!myPersonalEmanuelUserData.is_owner && <Checkbox id='isManager' name='isManager' disabled defaultChecked={isManager} />}

            {(props.closeDrawer != null) &&<Button
              key="sumitbutton"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={props.closeDrawer(false)}
            >
              Update profile
            </Button>}
            {(props.closeDrawer == null) &&<Button
              key="sumitbutton"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}              
            >
              Update profile
            </Button>}
          </Box> 
          <Grid container>
              <Grid item xs>
              <label id="label0">Login: </label>
                <Link key="userEmail" href="#" variant="body2">
                  {userEmail}
                </Link>
              </Grid>
               <Grid item>
               {(props.closeDrawer != null) && <Button onClick={props.closeDrawer(false)}>close</Button> }               
                {/* <Link key="forgotPwdLink" href="/pwdresetrequest"  variant="body2">
                  Change password
                </Link> */}
              </Grid> 
            </Grid>                
        </Box>
        <Copyright key="copyrightBlock" sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    );
};
