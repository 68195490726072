import * as React from 'react';
import { styled } from '@mui/material/styles';
import CoronavirusRoundedIcon from '@mui/icons-material/CoronavirusRounded';
import AirlinesRoundedIcon from '@mui/icons-material/AirlinesRounded';
import GppMaybeRoundedIcon from '@mui/icons-material/GppMaybeRounded';
import ThunderstormRoundedIcon from '@mui/icons-material/ThunderstormRounded';
import SportsMartialArtsRoundedIcon from '@mui/icons-material/SportsMartialArtsRounded';
import Filter2RoundedIcon from '@mui/icons-material/Filter2Rounded';
import SickRoundedIcon from '@mui/icons-material/SickRounded';
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import EventRepeatRoundedIcon from '@mui/icons-material/EventRepeatRounded';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { InputLabel, NativeSelect, Select } from '@mui/material';
import { Box } from '@mui/system';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));


export default function TemplateFilters(props) {

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    console.log('INITIALIZATION OF STATE OF TEMPLATE FILTER');
    console.log(props);
    const [tag, setTag] = React.useState(props.filterJson.tagFilter);
    const [responseOrder, setResponseOrder] = React.useState(props.filterJson.responseOrderFilter);
    const [airlines, setAirlines] = React.useState(props.filterJson.airlineFilter);
    const [myFlightType, setMyFlightType] = React.useState(props.filterJson.flightType);
    const [myJurisdiction, setMyJurisdiction] = React.useState(props.filterJson.jurisdictionFilter);

    const handleAirlines = (event) => {
        //const newFilterStateToSet={tagFilter: tag, airlineFilter: event.target.value, responseOrderFilter: responseOrder };
        const newFilterStateToSet={tagFilter: tag, airlineFilter:  event.target.value, responseOrderFilter: responseOrder, flightType: myFlightType, jurisdictionFilter: myJurisdiction};

        setAirlines(event.target.value);
        props.changeMyFilterData(newFilterStateToSet)
        console.log('THIS IS FILTER CONTENT');
        console.log(newFilterStateToSet);
    };

    const handleStatusType = (event) => {
        //const newFilterStateToSet={tagFilter: tag, airlineFilter: event.target.value, responseOrderFilter: responseOrder };
        const newFilterStateToSet={tagFilter: tag, airlineFilter:  airlines, responseOrderFilter: responseOrder,  flightType: event.target.value, jurisdictionFilter: myJurisdiction};

        setMyFlightType(event.target.value);
        props.changeMyFilterData(newFilterStateToSet)
        console.log('THIS IS FILTER CONTENT');
        console.log(newFilterStateToSet);
    };

    const handleTag = (event, newTag) => {
         //    const newFilterStateToSet={tagFilter: newTag, airlineFilter: airlines, responseOrderFilter: responseOrder };  
        //TMP: TODO: ked sa ti podari doriesit kombinovane filtre, mozes toto zmenit a vratit zakomentovany riadok vyssie.
        const newFilterStateToSet={tagFilter: newTag, airlineFilter: airlines, responseOrderFilter: responseOrder, flightType: myFlightType, jurisdictionFilter: myJurisdiction};

        setTag(newTag);
        props.changeMyFilterData(newFilterStateToSet)
        console.log('THIS IS FILTER CONTENT');
        console.log(newFilterStateToSet);
    };

    const handleOrder = (event, newOrder) => {
        const newFilterStateToSet={tagFilter: tag, airlineFilter: airlines, responseOrderFilter: newOrder,  flightType: myFlightType, jurisdictionFilter: myJurisdiction};
        setResponseOrder(newOrder);                
        props.changeMyFilterData(newFilterStateToSet)
        console.log('THIS IS FILTER CONTENT');
        console.log(newFilterStateToSet);
    };
    return (
        <div>
            <Paper
                elevation={0}
                sx={{
                    display: 'flex',
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    flexWrap: 'wrap',
                    textAlign: 'center',
                    alignItems: 'flex-end',
                    alignContent: 'flex-end',
                    justifyContent: 'center',
                }}
            >
                <StyledToggleButtonGroup
                    size="small"
                    value={tag}
                    exclusive
                    onChange={handleTag}
                    aria-label="tag filter"
                >
                    <ToggleButton value="airportStrike" aria-label="AIRPORT STRIKE RELATED TEMPLATES FILTER">
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">Airport STRIKE templates</Typography>
                                    {"Will"} <b>{'filter'}</b>{' just '}<u>{'Airport STRIKE'}</u> {' related templates.'}
                                    {" Rest templates will be hidden"}
                                </React.Fragment>
                            }
                        >
                            <SportsMartialArtsRoundedIcon />
                        </HtmlTooltip>
                    </ToggleButton>
                    <ToggleButton value="notam" aria-label="NOTAM RELATED TEMPLATES FILTER">

                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">NOTAMS templates</Typography>
                                    {"Will"} <b>{'filter'}</b>{' just '}<u>{'NOTAMS'}</u> {' related templates.'}
                                    {" Rest templates will be hidden"}
                                </React.Fragment>
                            }
                        >
                            <GppMaybeRoundedIcon />
                        </HtmlTooltip>

                    </ToggleButton>
                    <ToggleButton value="weather" aria-label="WEATHER RELATED TEMPLATES FILTER">

                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">WEATHER templates</Typography>
                                    {"Will"} <b>{'filter'}</b>{' just '}<u>{'WEATHER'}</u> {' related templates.'}
                                    {" Rest templates will be hidden"}
                                </React.Fragment>
                            }
                        >
                            <ThunderstormRoundedIcon />
                        </HtmlTooltip>

                    </ToggleButton>
                    <ToggleButton value="covid" aria-label="COVID RELATED TEMPLATES FILTER">

                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">COVID-19 templates</Typography>
                                    {"Will"} <b>{'filter'}</b>{' just '}<u>{'COVID-19'}</u> {' related templates.'}
                                    {" Rest templates will be hidden"}
                                </React.Fragment>
                            }
                        >
                            <SickRoundedIcon />
                        </HtmlTooltip>
                    </ToggleButton>
                    <ToggleButton value="2years" aria-label="2 YEARS RELATED TEMPLATES FILTER">

                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">2 YEARS rule templates</Typography>
                                    {"Will"} <b>{'filter'}</b>{' just templates relevant if claim is '}<u>{'2 years'}</u> {'older than the flight.'}
                                    {" Rest templates will be hidden"}
                                </React.Fragment>
                            }
                        >
                            <Filter2RoundedIcon />
                        </HtmlTooltip>

                    </ToggleButton>
                    <ToggleButton value="14days" aria-label="14 DAYS RULE RELATED TEMPLATES FILTER">

                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">14 DAYS rule template</Typography>
                                    {"Will"} <b>{'filter'}</b>{' just templates relevant if flight was '}<u>{'CANCELED 14 days'}</u> {' before scheduled departure.'}
                                    {" Rest templates will be hidden"}
                                </React.Fragment>
                            }
                        >
                            <EventRepeatRoundedIcon />
                        </HtmlTooltip>
                    </ToggleButton>

                    <ToggleButton value="payCompensation" aria-label="TEMPLATES SHOWEN WHEN PAY COMPENSATION IS SUGGESTED">
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <Typography color="inherit">Compensation PAY suggested</Typography>
                                {"Will"} <b>{'filter'}</b>{' just templates relevant if '}<u>{'COMPENSATION PAY'}</u> {' is relevant.'}
                                {" Rest templates will be hidden"}
                            </React.Fragment>
                        }
                    >
                        <LocalAtmRoundedIcon /> 
                    </HtmlTooltip>
                    </ToggleButton>
                    <ToggleButton value="noFilter" aria-label="SHOW ALL TEMPLATES">

                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">All templates</Typography>
                                    {"Will"} <b>{'CANCEL'}</b>{' any '}<u>{'FILTER'}</u> {' and show all templates'}
                                </React.Fragment>
                            }
                        >
                            <FormatAlignJustifyIcon />
                        </HtmlTooltip>
                    </ToggleButton>
                </StyledToggleButtonGroup>
                <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

                <StyledToggleButtonGroup
                    size="small"
                    // value={airlines}
                    // onChange={handleAirlines}
                    aria-label="airlines selector"
                >

<Box sx={{ minWidth: 120 }}>
        <NativeSelect
          defaultValue={airlines}
          onChange={handleAirlines}
          inputProps={{
            name: 'airline',
            id: 'uncontrolled-native',
          }}
        >
          <option value={'allAirlines'}>ALL AIRLINES</option>
          <option value={'5W'}>5W</option>
          <option value={'W4'}>W4</option>
          <option value={'W6'}>W6</option>
          <option value={'W9'}>W9</option>
        </NativeSelect>
    </Box>
                </StyledToggleButtonGroup>

                <StyledToggleButtonGroup
                    size="small"
                    // value={airlines}
                    // onChange={handleAirlines}
                    aria-label="airlines selector"
                >

                    <Box sx={{ minWidth: 120 }}>
                            <NativeSelect
                            defaultValue={myFlightType}
                            onChange={handleStatusType}
                            inputProps={{
                                name: 'statusType',
                                id: 'uncontrolled-native',
                            }}
                            >
                            <option value={'allTypes'}>ALL TYPES</option>
                            <option value={'canceled'}>canceled</option>
                            <option value={'delay'}>delay</option>
                            <option value={'other'}>other</option>
                            </NativeSelect>
                        </Box>
                </StyledToggleButtonGroup>


                <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

                <StyledToggleButtonGroup
                    size="small"
                    value={responseOrder}
                    exclusive
                    onChange={handleOrder}
                    aria-label="order filter"
                >


                    <ToggleButton value="1st" aria-label="FIRST REPLYS ONLY">
                         <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">First responses only</Typography>
                                    {"Will"} <b>{'filter'}</b>{' just '}<u>{'FIRST'}</u> {' templates in chain of possible responses'}
                                </React.Fragment>
                            }
                        >
                        <div>1st</div>
                        </HtmlTooltip>
                    </ToggleButton>
                    <ToggleButton value="2nd" aria-label="SECOND REPLYS ONLY">
                         <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">SECOND responses only</Typography>
                                    {"Will"} <b>{'filter'}</b>{' just '}<u>{'SECOND'}</u> {' templates in chain of possible responses'}
                                </React.Fragment>
                            }
                        >
                        <div>2nd</div>
                        </HtmlTooltip>

                    </ToggleButton>
                    <ToggleButton value="3rd" aria-label="THIRD REPLYS ONLY">
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">THIRD response only filter</Typography>
                                    {"Will"} <b>{'filter'}</b>{' just '}<u>{'THIRD'}</u> {' templates in chain of possible responses'}
                                </React.Fragment>
                            }
                        >
                        <div>3rd</div>
                        </HtmlTooltip>

                    </ToggleButton>
                    <ToggleButton value="4th" aria-label="FIRTH REPLYS ONLY">
                    <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">FORTH response only filter</Typography>
                                    {"Will"} <b>{'filter'}</b>{' just '}<u>{'FORTH'}</u> {' templates in chain of possible responses'}
                                </React.Fragment>
                            }
                        >
                        <div>4th</div>
                        </HtmlTooltip>

                    </ToggleButton>
                    <ToggleButton value="5th" aria-label="FIFTH REPLYS ONLY">
                    <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">FIFTH response only filter</Typography>
                                    {"Will"} <b>{'filter'}</b>{' just '}<u>{'FIFTH'}</u> {' templates in chain of possible responses'}
                                </React.Fragment>
                            }
                        >
                        <div>5th</div>
                        </HtmlTooltip>

                    </ToggleButton>
                    <ToggleButton value="showAll" aria-label="SHOW ALL TEMPLATES">
                        <FormatAlignJustifyIcon />
                    </ToggleButton>
                </StyledToggleButtonGroup>
            </Paper>
        </div>
    );
}