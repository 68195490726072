import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AdbIcon from '@mui/icons-material/Adb';
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { red } from '@mui/material/colors';

function preventDefault(event) {
  event.preventDefault();
}

export default function DataBox3(props) {
  return (
    <React.Fragment>      
       <Grid container spacing={2}>
        <Grid item xs={12} md={4} container alignItems="center" justifyContent="center"> 
        <GavelRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 60, mr: 2 }} />
        </Grid>
        <Grid item xs={12} md={8} alignItems="center" justifyContent="center" >
          <h1>JURISDICTION</h1>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={1} md={1} container alignItems="center" justifyContent="center"> 
              { props.flightEvaluationEngine.eU261Applicable &&  <DoneRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 20, mr: 2,color: "rgba(124, 252, 0, 0.9)" }} /> }
              { !props.flightEvaluationEngine.eU261Applicable &&  <CloseRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 20, mr: 2,color: "rgba(255, 0, 0, 0.9)" }} />  }
              </Grid>  
              <Grid item xs={11} md={11} container alignItems="left"> 
                EU261/2004 Applicable
              </Grid>  
            </Grid>            
          </Typography>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
             <Grid container spacing={2}>
              <Grid item xs={1} md={1} container alignItems="center" justifyContent="center"> 
              { props.flightEvaluationEngine.greatBritainApplicable &&  <DoneRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 20, mr: 2,color: "rgba(124, 252, 0, 0.9)" }} /> }
              { !props.flightEvaluationEngine.greatBritainApplicable &&  <CloseRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 20, mr: 2,color: "rgba(255, 0, 0, 0.9)" }} />  }
              </Grid>  
              <Grid item xs={11} md={11} container alignItems="left"> 
                Great Britain Applicable
              </Grid>  
            </Grid>            
          </Typography>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
          <Grid container spacing={2}>
              <Grid item xs={1} md={1} container alignItems="center" justifyContent="center"> 
              { (props.flightEvaluationEngine.polishAirline || props.flightEvaluationEngine.polishFlight || props.flightEvaluationEngine.germanAirlines || props.flightEvaluationEngine.germanFlight || props.flightEvaluationEngine.austrianAirline || props.flightEvaluationEngine.austrianFlight) &&  <DoneRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 20, mr: 2,color: "rgba(124, 252, 0, 0.9)" }} /> }
              { !(props.flightEvaluationEngine.polishAirline || props.flightEvaluationEngine.polishFlight || props.flightEvaluationEngine.germanAirlines || props.flightEvaluationEngine.germanFlight || props.flightEvaluationEngine.austrianAirline || props.flightEvaluationEngine.austrianFlight)  &&  <CloseRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 20, mr: 2,color: "rgba(255, 0, 0, 0.9)" }} />  }
              </Grid>  
              <Grid item xs={11} md={11} container alignItems="left"> 
                 Germany/Austria/Poland
              </Grid>  
            </Grid>      
            
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}