import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import UserEdit from './UserEdit';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

export default function EditUserDrawer(props) {
  const [state, setState] = React.useState({});

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      // sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      //onClick={toggleDrawer(anchor, false)}
      //onKeyDown={toggleDrawer(anchor, false)}
    >      
      <UserEdit closeDrawer={() => toggleDrawer(anchor, false)} emanuelUser={props.emanuelUser} />
      <Divider />
      
    </Box>
  );

  return (
    <>
      {
        <React.Fragment key='New'>
          <Button onClick={toggleDrawer('New', true)}><EditRoundedIcon sx={{ display: { md: 'flex', xs: 'none' }, mr: 2, color: "rgba(100, 100, 100, 0.9)"}}/></Button>
          <Drawer
            anchor='right'
            open={state['New']}
            onClose={toggleDrawer('New', false)}
          >
            {list('New')}
          </Drawer>
        </React.Fragment>
      }
    </>
  );
}