import "../styles.css";
import React, { useRef, useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import AccordeonItem from "../components/templateSelector/AccordeonItem";
import { supabase } from "../lib/supabase";
import { Session, User } from '@supabase/supabase-js';

import { Button } from "@mui/material";
import { Box } from "@mui/system";
import JsonDataVisualizer from "../components/vizualizer/JsonDataVisualizer";
import TemplatesSelector from "../components/templateSelector/TemplatesSelector";
import { useUser } from "../components/authContextProvider/AuthContext";

//import AirLawFlightEntity from "./data/ALPDisruptedObject.json"
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import DataBox1 from "../components/dashboard/DataBox1";
import DataBox2 from "../components/dashboard/DataBox2";
import DataBox3 from "../components/dashboard/DataBox3";
import DataBoxWeather from "../components/dashboard/DataBoxWeather";
import DataBoxWeather2 from "../components/dashboard/DataBoxWeather2";
import ReportMistakeDrawer from "../components/mistakes/ReportMistakeDrawer";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function FlightDetails() {

  const { user } = useUser();
  const [danielFilter, setFilterJson] = useState(sessionStorage.getItem("danielFilterSuggestion") || null);
  const [activityCorrelationID, setActivityCorrelationID] = useState(sessionStorage.getItem("activityCorrelationID") || null);
  //const [initialFlightStatus, setInitialFlightStatus] = useState("other");
  const [messageTemplates, setTemplates] = useState([]);
  const [danielJson, setDanielJson] = useState(JSON.parse(sessionStorage.getItem('AirLawDataEntity')));
  const [externalFlightVerificationLink, setExternalFlightVerificationLink] = useState('');
  const [externalFlightVerificationText, setExternalFlightVerificationText] = useState('');
  const [box1Data, setBox1Data] =  useState(JSON.parse(sessionStorage.getItem('AirLawDataEntity')).airLawData || null);
  const [box2Data, setBox2Data] = useState(JSON.parse(sessionStorage.getItem('AirLawDataEntity')).airLawData?.flightEvaluationEngine || null);
  const [box3Data, setBox3Data] = useState(JSON.parse(sessionStorage.getItem('AirLawDataEntity')).airLawData?.flightEvaluationEngine || null);
  const [weatherBox1Data, setWeatherBox1Data] = useState(JSON.parse(sessionStorage.getItem('AirLawDataEntity')) || null);
  const [weatherBox2Data, setWeatherBox2Data] = useState(JSON.parse(sessionStorage.getItem('AirLawDataEntity')) || null);
  const [flightNumber, setFlightNumber] = useState(JSON.parse(sessionStorage.getItem('AirLawDataEntity')).airLawData?.flight?.number || null);
  const [flightFrom, setFlightFrom] = useState(JSON.parse(sessionStorage.getItem('AirLawDataEntity')).airLawData?.departure?.iataCode || null);
  const [flightDate, setFlightDate] = useState(JSON.parse(sessionStorage.getItem('AirLawDataEntity')).airLawData?.departure?.scheduledTime || null);
  const [flightTo, setFlightTo] = useState(JSON.parse(sessionStorage.getItem('AirLawDataEntity')).airLawData?.arrival?.iataCode || null);
  const [dropZoneData, setDropZoneData] = useState(flightNumber + " " + flightFrom + " - "+ flightTo + " - "+ flightDate);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%'
  }));

  useEffect(() => {

    (async function () {
      console.log("start setting up Daniels Data");
      try {
        const danielsData = sessionStorage.getItem('AirLawDataEntity');
        //console.log(danielsData);
        setDanielJson(JSON.parse(danielsData));
        setExternalFlightVerificationLink(process.env.REACT_APP_EXTERNAL_LINK);        
        setExternalFlightVerificationText(process.env.REACT_APP_EXTERNAL_LINK_TEXT);        
        setWeatherBox1Data(danielJson);
        setWeatherBox2Data(danielJson);
        setBox1Data(danielJson?.airLawData);
        setBox2Data(danielJson?.airLawData?.flightEvaluationEngine);
        setBox3Data(danielJson?.airLawData?.flightEvaluationEngine);
        

        // console.log('Daniels raw data:', danielsData);
        // console.log('Air Law Data:' , airLawData);
        if (!danielJson?.airLawData?.flightEvaluationEngine) {
          console.log('HUSTON WE HAVE A PROBLEM: DANIES SUMMARY IS EMPTY - THERE IS NOTHING TO SETUP');
        }
        else {          
         // console.log('Daniels summary:' , danielsSummary);
         //setInitialFlightStatus(danielJson.airLawData.status);
        }
      }
      catch (e) {
        console.error(e);
        console.log();
      }
    })();
  }, []);

  return (
    <div className="App">
      {user ? (
        <Box>
          {danielJson?.dataSourcesProblemDetected && <Stack sx={{ width: '100%' }} spacing={2}>    
            <Alert severity="warning">Data inconsistency between multiple data sources was detected! Please verify the flight in your own data source.</Alert>     
          </Stack>}
          <Box padding={3}></Box>
          <Box>
            <h1>Flight details </h1><h1>{weatherBox1Data?.airLawData?.flight?.iataNumber?.toUpperCase()} {weatherBox1Data?.airLawData?.airline?.name?.toUpperCase()}</h1>
            <Grid container spacing={1} height='180px'>
              <Grid item xs={12} md={4} height='100%'>
                <Item height='100%'><DataBox1 airLawData={box1Data}/></Item>
              </Grid>
              <Grid item xs={12} md={4}>
              <Item><DataBox2 flightEvaluationEngine={box2Data} externalFlightVerificationLink={externalFlightVerificationLink} externalFlightVerificationText={externalFlightVerificationText}/></Item>
              </Grid>
              <Grid item xs={12} md={4}>
              <Item><DataBox3 flightEvaluationEngine={box3Data} /></Item>
              </Grid>
            </Grid>
            <Box padding={3}></Box>
            <Grid container spacing={1} >
              <Grid item xs={12} md={6}>
                <Item><DataBoxWeather danielJson={weatherBox1Data} /></Item>
              </Grid>
              <Grid item xs={12} md={6}>
                <Item><DataBoxWeather2 danielJson={weatherBox2Data}/></Item>
              </Grid>
            </Grid>
          </Box>
          <Box padding={3}>
            <h1>Some of templates bellow fits your needs?</h1>
            <TemplatesSelector initFilter={JSON.parse(danielFilter)} user={user} editorView={false}/>            
          </Box>
          <ReportMistakeDrawer danielJson={JSON.stringify(weatherBox1Data)} filters={danielFilter} activityCorrelationID={activityCorrelationID}  myuser={user} dropzone={dropZoneData}/>
        </Box>) : (
        <Navigate replace to="/login" />
      )}
    </div>
  );

}