import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import {supabase} from '../../lib/supabase';
import { useUser } from "../authContextProvider/AuthContext";

const pages = ['Flight Search', 'Templates','Manual','About'];
const settings = ['Profile', 'Logout'];
const manager = ['Users', 'Mistakes'];

function ResponsiveAppBar() {
  const user = useUser();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [emanuelUser, setEmanuelUser] = React.useState(JSON.parse(localStorage.getItem("emanuelUser")) || null); 

  useEffect(() => {
    console.log("------------!!!!!!!!!!!!!!!!!!!!")
  }, [emanuelUser, user]);

  useEffect(() => {
    var emUser = null;
    try
    {
      console.log("!!!!!!!!!!!!!!!!!!!!------------")
      emUser = localStorage.getItem("emanuelUser");
      if (emUser != null)
      setEmanuelUser(JSON.parse(emUser)) ;  
    }
    catch(exception)
    { throw exception;}
    
  }, [user, anchorElUser]);



  function strCompare (stringA, stringB) {
    if (stringA === stringB) return true;
    else return false;
  }
  
  const logActivity = async (userData, activity) => {
    console.log("user data log:",userData);
    //const correlationID = uuid();
    
    const { data, error } = await supabase
      .from('logUserActivity')
      .insert([
        { user_email: userData.user.email, user_id: userData.user.id, type: activity, correlationID: null },
      ])
  }

  const handleMenuItemClick = async (clickedPage) => {
      console.log("This is clickpage log:", clickedPage);
      console.log(strCompare(clickedPage,'Templates'));
      if(strCompare(clickedPage,'Templates'))  
      {
        handleCloseNavMenu();
        navigate('/templates');  
      }
      if(strCompare(clickedPage,'Manual'))
      {
        handleCloseNavMenu();
        navigate('/login');  
      }  
      if(strCompare(clickedPage,'About'))
      {
        handleCloseNavMenu();
        navigate('/about');  
      }
      if(strCompare(clickedPage,'Flight Search'))
      {
        handleCloseNavMenu();
        navigate('/flightsearch');  
      }  
      if(strCompare(clickedPage,'Profile'))
      {
        handleCloseUserMenu();
        navigate('/userprofile');  
      }  
      if(strCompare(clickedPage,'Users'))
      {
        handleCloseUserMenu();
        navigate('/usermanagement');  
      }  
      if(strCompare(clickedPage,'Mistakes'))
      {
        handleCloseUserMenu();
        navigate('/mistakemanagement');  
      }  
      if(strCompare(clickedPage,'Logout'))
      {        
        const myPromise = await logActivity(user, 'logout');        
        
        const { error } = await supabase.auth.signOut();
        localStorage.removeItem('emanuelUser');
        setEmanuelUser(null);        
        handleCloseUserMenu();
        navigate('/login');   
      }  
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <img alt="LOGO" src="../../images/emanuelLogo.png" height={35} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            EMANUEL
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={()=> handleMenuItemClick(page)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>          
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            EMANUEL
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button key={page} onClick={() => handleMenuItemClick(page)}                                
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Agent" src="#" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (emanuelUser != null &&
                <MenuItem key={setting} onClick={() => handleMenuItemClick(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
               {manager.map((manager) => (emanuelUser != null && emanuelUser.is_manager && 
                <MenuItem key={manager} onClick={() => handleMenuItemClick(manager)}>
                  <Typography textAlign="center">{manager}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
