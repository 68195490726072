import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AdbIcon from '@mui/icons-material/Adb';
import AirplanemodeInactiveRoundedIcon from '@mui/icons-material/AirplanemodeInactiveRounded';
import FlightLandRoundedIcon from '@mui/icons-material/FlightLandRounded';
import FlightTakeoffRoundedIcon from '@mui/icons-material/FlightTakeoffRounded';
import DoneOutlineRoundedIcon from '@mui/icons-material/DoneOutlineRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import AirlinesRoundedIcon from '@mui/icons-material/AirlinesRounded';
import AltRouteIcon from '@mui/icons-material/AltRoute';

import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { red } from '@mui/material/colors';

function preventDefault(event) {
  event.preventDefault();
}

export default function DataBox1(props) {

  console.log('BOX1 PROPS:', props);

  return (
    <React.Fragment>      
       <Grid container spacing={2} height='100%'>
        <Grid item xs={12} md={4} container alignItems="center" justifyContent="center">
        { props.airLawData.status == "canceled" && <AirplanemodeInactiveRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 60, mr: 2, color: "rgba(230, 0, 0, 0.9)"  }} /> }
        { props.airLawData.status == "delay" && props.airLawData.statusType == "arrival" && <FlightLandRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 60, mr: 2,color: "rgba(233, 213, 2, 1)"  }} />}
        { props.airLawData.status == "delay" && props.airLawData.statusType == "departure" && <FlightTakeoffRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 60, mr: 2, color: "rgba(233, 213, 2, 1)"  }} /> }
        { props.airLawData.status == "other" && props.airLawData.statusType == "diverted" && <AltRouteIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 60, mr: 2,color:  "rgba(230, 0, 0, 0.9)"  }} /> }
        { props.airLawData.status == "other" &&  props.airLawData.statusType != "diverted" &&  <AirlinesRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 60, mr: 2,color: "rgba(0, 255, 0, 0.9)" }} /> }
        {/* {props.airLawData.status == "OK" && <PriorityHighRoundedIcon sx={{ display: { md: 'flex', xs: 'none' },  fontSize: 60, mr: 2,color: "#1976d2" }} /> } */}
        </Grid>
        <Grid item xs={12} md={8}>
        { props.airLawData.status == "canceled" && <h1>CANCELED</h1> }
        { props.airLawData.status == "delay" && props.airLawData.statusType == "arrival" && <h1>ARRIVAL DELAY</h1>}
        { props.airLawData.status == "delay" && props.airLawData.statusType == "departure" && <h1>DEPARTURE DELAY</h1> }
        { props.airLawData.status == "other" && props.airLawData.statusType == "diverted" &&  <h1>DIVERTED</h1> }
        { props.airLawData.status == "other" &&  props.airLawData.statusType != "diverted" && <h1>SEEMS OK</h1> }

          <Typography component="p" variant="h5">
           { props.airLawData.departure.iataCode.toUpperCase() } - {props.airLawData.arrival.iataCode.toUpperCase() }
          </Typography>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            on { props.airLawData.departure.scheduledTime }
          </Typography>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            Distance: { props.airLawData.distance } km
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}