import React, { useRef, useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import AccordeonItem from "./AccordeonItem";
import { supabase } from "../../lib/supabase";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import TemplateFilters from "./TemplateFilters";

export default function TemplatesSelector(props) {

  console.log(props);
  const [messageTemplates, setTemplates] = useState([]);
  //const [firstFlightStatus, setFirstFlightStatus] = useState(props?.firstFlightStatus)
  const [filterJson, setFilterJson] = useState(props?.initFilter || null);

  const prepData = async (myFilter) => {
    console.log('I`m PREPDATA:')
    console.log(myFilter);          
    const { data, error } = await supabase.rpc('get_templates', { i_airline_iata: myFilter.airlineFilter, i_flight_type: myFilter.flightType, i_tag_name: myFilter.tagFilter, i_order: myFilter.responseOrderFilter, i_jurisdiction: myFilter.jurisdictionFilter});
    if (error) {
            console.log("I`m the get_templates (with parameters) Function returning error:")
              console.log(error);
              throw error;
            }
    else {
              console.log("I`m the get_templates Function returning data:")
              console.log(data);
              const result = data;
            //  const result = data.map((value) => (value.templates));
              console.log(result);
    
              setTemplates(result);
    }



    // if (myFilter.airlineFilter == 'allAirlines') {
    //   if (myFilter.tagFilter == 'noFilter') {        
    //     if (myFilter.responseOrderFilter == 'shawAll') {
    //       const { data, error } = await supabase.from('templates').select('id, sc_name,sc_number, sc_instruction, sc_shortDescription, jurisdiction_restricting_useage, placeholders').order('sc_number', { ascending: true });
    //       if (error) {
    //         console.log("I`m the getSupabaseTemplates Function returning error:")
    //         console.log(error);
    //         throw error;
    //       }
    //       else {
    //         console.log("I`m the getSupabaseTemplates Function returning data:")
    //         console.log(data);
  
    //         setTemplates(data);
    //       }
    //     }
    //     else
    //     {
    //       const { data, error } = await supabase.from('responseOrders-templates').select('templates(id, sc_name, sc_number, sc_instruction, sc_shortDescription, placeholders),responseOrderName').eq('responseOrderName', myFilter.responseOrderFilter);
    //       if (error) {
    //         console.log("I`m the getSupabaseTemplates (with categoryFilter) Function returning error:")
    //         console.log(error);
    //         throw error;
    //       }
    //       else {
    //         console.log("I`m the getSupabaseTemplates (with categoryFilter) Function returning data:")
    //         console.log(data);
    //         const result = data.map((value) => (value.templates));
    //         console.log(result);
  
    //         setTemplates(result);
    //       }
    //     }       
    //   }
    //   else {
    //     console.log('!!!!!!!!!!!!!!!!FLIGHT STATUS !!!!!!!!!!!!!!!!!!!!!!:', flightStatus );
    //     const { data, error } = await supabase.from('tags').select('templates(id, sc_name, sc_number, sc_instruction, sc_shortDescription, placeholders),tagName,tagFlightStatus').match({tagName: myFilter.tagFilter, tagFlightStatus: flightStatus})  //.eq('tagName', myFilter.tagFilter).ilike('tagFlightStatus', 'other')
    //     if (error) {
    //       console.log("I`m the getSupabaseTemplates (with categoryFilter) Function returning error:")
    //       console.log(error);
    //       throw error;
    //     }
    //     else {
    //       console.log("I`m the getSupabaseTemplates (with categoryFilter) Function returning data:")
    //       console.log(data);
    //       const result = data.map((value) => (value.templates));
    //       console.log(result);

    //       setTemplates(result);
    //     }
    //   }
    // }
    // else
    // {
    //   const { data, error } = await supabase.from('airlines-tamplates').select('templates(id, sc_name, sc_number, sc_instruction, sc_shortDescription, placeholders),airline').eq('airline', myFilter.airlineFilter);
    //   if (error) {
    //     console.log("I`m the getSupabaseTemplates (with categoryFilter) Function returning error:")
    //     console.log(error);
    //     throw error;
    //   }
    //   else {
    //     console.log("I`m the getSupabaseTemplates (with categoryFilter) Function returning data:")
    //     console.log(data);
    //     const result = data.map((value) => (value.templates));
    //     console.log(result);

    //     setTemplates(result);
    //   }
    // }

  };

  // const handleClick = async (selectedTagName) => {
  //       const { data, error } = await supabase.from('tags').select('template').eq('tagName', selectedTagName);   
  //       if (error) {
  //         console.log("I`m the getSupabaseTAGS Function returning error:")
  //         console.log(error);
  //         throw error;
  //       }
  //       else {
  //         console.log("I`m the getSupabaseTAGS Function returning data:")
  //         console.log(data);

  //         const result = [];
  //         for(const template in data)
  //           result.push(data[template]);
  //         console.log(result);
  //         //prepData(result);  

  //       }       
  // }

  useEffect(() => {
      prepData(filterJson);
  }, []);

  function handleFilterElementClick(newFilterJson) {
    setFilterJson(newFilterJson);
    //prepData(filter);
    console.log('I`m TEMPLATESSELECTOR.JS - HANDLEFILTERELEMENTCLICK (filterJSON state value):');
    console.log(newFilterJson);
    prepData(newFilterJson);

  }

    return (
      <div>
        <Box textAlign={'center'}>
          <TemplateFilters filterJson={filterJson} changeMyFilterData={filterJson => handleFilterElementClick(filterJson)} />
        </Box>
        <Box padding={3}>
          {messageTemplates != null && messageTemplates.map((myTemplate, index) => (<AccordeonItem
            placeholders={myTemplate.placeholders}
            longDescription={myTemplate.sc_instruction }
            shortDescription={myTemplate.sc_shortDescription}
            templateName={myTemplate.sc_name}
            templateNumber={myTemplate.sc_number}                        
            emanuelTemplateId={myTemplate.id}
            countriesLimitations={myTemplate.jurisdiction_restricting_useage}
            //wundergroundLink = {}
            user={props.user}
            editorView={props.editorView}
          />
          ))}
        </Box>
        {/* <AccordeonItem 
                                              longDescription="long" 
                                              shortDescription="short"
                                              templateName="template name"
                                              templateNumber="10"
                                              emanuelTemplateId="1567965" />                                                */}
      </div>
    );
}