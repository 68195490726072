import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';

import {supabase} from '../../lib/supabase';
import ResetUserPwdDialog from './ResetUserPwdDialog';
import DeleteUserDialog from './DeleteUserDialog';
import EditUserDrawer from './EditUserDrawer';


export default function AllUsers() {
  const [allEmanuelUsers, setAllEmanuelUsers] = useState([]);

  // useEffect(() => {
    
    // const emanuelUsersChanel = supabase.channel('custom-all-channel')
    // .on(
    //   'postgres_changes',
    //   { event: '*', schema: 'public', table: 'emanuelUsers' },
    //   (payload) => {
    //     console.log('Change received!', payload)
    //   }
    // )
    // .subscribe()
    // console.log('emanuelUsers', emanuelUsersChanel);

    // return emanuelUsersChanel.unsubscribe();
  // }, []);

  useEffect(() => {
    fetchUsers();   
    
    const emanuelUsersChanel = supabase.channel('custom-all-channel')
    .on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'emanuelUsers' },
      (payload) => {
        fetchUsers();   
      }
    )
    .subscribe()
    console.log('emanuelUsers', emanuelUsersChanel);

    //return emanuelUsersChanel.unsubscribe();
  }, []);

  async function fetchUsers() {
    const { data, error } = await supabase.from('emanuelUsers').select('full_name, email, is_manager, supabase_user, secondary_email, phone_number, id')
    if (error) {
      console.log("I`m the AllUsers Component prepData Function returning error:")
      console.log(error);
      throw error;
    }
    else {      
      console.log('allEmanuelUsers: ',data);          
      setAllEmanuelUsers(data);
    }
  console.log(data);
}
  return (     
    <TableContainer sx={{ maxWidth: '99%', textAlign: 'center' }} component={Paper}>
      <Table sx={{ minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>User Name</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Manager Rights</TableCell>
            <TableCell align="right"></TableCell>            
          </TableRow>
        </TableHead>
        <TableBody>
          {allEmanuelUsers.map((row) => (
            <TableRow
              key={row.supabase_user}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <div className='row'><b>{row.full_name}</b> <EditUserDrawer emanuelUser={row}/></div>
              </TableCell>
              <TableCell align="right">{row.email}</TableCell>
              <TableCell align="right">{row.is_manager && <SupervisorAccountRoundedIcon />}{!row.is_manager && <PersonRoundedIcon/>}</TableCell>
              <TableCell align="right"> <div className='row'>
                {/* <DeleteUserDialog userName={row.full_name} userId={row.supabase_user}/> */}
              <ResetUserPwdDialog userName={row.full_name} userEmail={row.email}/></div></TableCell>                            
            </TableRow>
          ))}
        </TableBody>
      </Table>      
    </TableContainer>    
  );
}