import "../styles.css";
import React, { useRef, useEffect, useState } from 'react';
import FlightDashboard from "../components/dashboard/FlightDashboard";
import { Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import AccordeonItem from "../components/templateSelector/AccordeonItem";
import { supabase } from "../lib/supabase";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import JsonDataVisualizer from "../components/vizualizer/JsonDataVisualizer";
import TemplatesSelector from "../components/templateSelector/TemplatesSelector";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useUser } from "../components/authContextProvider/AuthContext";

export default function templates() {
  const navigate = useNavigate();
  const [emanuelUser, setEmanuelUser] = useState(JSON.parse(localStorage.getItem("emanuelUser")) || null);  
  const [editView, setEditView] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [initFilter, setFilterJson] = useState({ tagFilter: 'noFilter', airlineFilter: 'allAirlines', responseOrderFilter: 'showAll', flightType: 'allTypes', jurisdictionFilter: null });
  const { user } = useUser();
  const [newTemplateId, setNewTempalteId] = useState('');
  //localStorage.removeItem('responseOrderFilters');


  const prepFunction = () => {
    //in a future dynamic loading of all filters + their icons from database
    sessionStorage.removeItem('responseOrderFilters');
    sessionStorage.removeItem('airlineFilters');
    sessionStorage.removeItem('tagCanceledFilters');
    sessionStorage.removeItem('tagDelayFilters');
    sessionStorage.removeItem('tagOtherFilters');
    
    return true;
  };

  const handleNewTemplateClick = async () => {    
    const emptyEditorState =  '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';
    sessionStorage.setItem('tagDelayFilters', JSON.stringify({
      'covid': false,
      'airportStrike': false,
      'notam': false,
      'weather': false,
      '2years': false,
      'delayLessThan3hours': false,
      '3hours': false,
      '5hours': false,
      'payCompensation': false
    })); 

    sessionStorage.setItem('tagCanceledFilters', JSON.stringify({
      'covid': false,
      'airportStrike': false,
      'notam': false,
      'weather': false,
      '2years': false,
      '14days': false,
      'payCompensation': false
    })); 

    sessionStorage.setItem('tagOtherFilters', JSON.stringify({
      'covid': false,
      'airportStrike': false,
      'notam': false,
      'weather': false,
      '2years': false,
      'diverted': false,
      'showAllways': false
    }));

    sessionStorage.setItem('airlineFilters', JSON.stringify({
      '5W': false,
      'W4': false,    
      'W6': false,
      'W9': false      
    }));

    sessionStorage.setItem('responseOrderFilters', JSON.stringify({
      '1st': false,
      '2nd': false,
      '3rd': false,
      '4th': false,
      '5th': false
    }));    

    const { data, error } = await supabase.from('templates').insert([
      { 'EN': emptyEditorState, 'PL': emptyEditorState, 'IT': emptyEditorState },
      ]).select('id');

      if(error) {console.log(error);}
      else {
        console.log('new template id is:', data);
        const arrayOfIds = data.map(item => Object.values(item));
        console.log('The first id is:', arrayOfIds[0]);

        setNewTempalteId(data);
        sessionStorage.setItem('templateName', '');
        sessionStorage.setItem('templateNumber', '');
        sessionStorage.setItem('templateShortDescription', '');
        sessionStorage.setItem('templateLongDescription', '');
        sessionStorage.setItem('templateCountriesLimitation', '');
        sessionStorage.setItem('messageEditor', emptyEditorState);
        navigate('/templatemanager?templateId='+arrayOfIds[0]+'&lang='+'EN');
      }
    
  }

  const handleChangeSwitch = e => {
    const val = e.target.checked;
    setEditView(val);
  }
//<Switch style={{ marginTop: 0 }}  id="editor-switch" name="editor-switch"  value={editView} onValueChange={(value) => setEditView({ switchDriverOnlineValue: !value })} />
  useEffect(() => {
    setLogoUrl(process.env.REACT_APP_LOGO_URL);
    //TODO FIX: GET THIS INFORMATION FROM USER ROLE TABLE INSTEAD OF PARAM
    //setEditView(process.env.REACT_APP_IS_MANAGER);
    setEditView(false);
    console.log('editView Value: ', editView);
    if(!emanuelUser.is_manager) prepFunction();
  }, []);

  return (
    <div className="App">

      
      {user ? (<Box>
        <Box padding={3}>        
          {(emanuelUser.is_manager && <Button sx={{ width: 300, margin: '20px' }} variant="contained" onClick={handleNewTemplateClick}>New Template</Button>)}               
          {(emanuelUser.is_manager && <FormControlLabel control={ <Switch onChange={handleChangeSwitch} value={editView} />} label="Templates edit mode" />)}        
        </Box>
        <Box>
          <div><img src={logoUrl} alt="Logo of the customer" height="60" style={{ margin: '100px 0px 0px 0px' }} /><h1>Search templates</h1></div>
        </Box>
        <TemplatesSelector initFilter={initFilter} user={user} editorView={editView}/>
      </Box>) : (
        <Navigate replace to="/login" />
      )}
    </div>
  );
}