import React, {useState, useEffect} from "react";
//import AirLawFlightEntity from "./data/ALPDisruptedObject.json"
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import DataBox1 from "./DataBox1";
import DataBox2 from "./DataBox2";
import DataBox3 from "./DataBox3";
import DataBoxWeather from "./DataBoxWeather";
import DataBoxWeather2 from "./DataBoxWeather2";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%'
}));

export default function FlightDashboard(props)  {
  
    const [AirLawFlightEntity, setAirLawFlightEntity] = useState(null); 
    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false); 
    useEffect(() => {
          const loggedInUser = localStorage.getItem("authenticated");
          const AirLawFlightEntityString = sessionStorage.getItem("AirLawDataEntity");
          console.log("useEffectFlightLog:");
          console.log(loggedInUser);
          if (loggedInUser != null) {
            console.log("Im inside:");
            setAuthenticated(loggedInUser);
          if (AirLawFlightEntityString != null){
            setAirLawFlightEntity(JSON.parse(AirLawFlightEntityString));
          }
          }
      }, []);  

      const handleClick = () => {
          console.log("Data entity from local store: ");
          console.log({AirLawFlightEntity});
      };

  return (
    <Box>
      <h1>Flight details W6 2375</h1>
      <Grid container spacing={1} height='180px'>
        <Grid item xs={12} md={4} height='100%'>
          <Item height='100%'><DataBox1 /></Item>
        </Grid>
        <Grid item xs={12} md={4}>
         <Item><DataBox2 /></Item>
        </Grid>
        <Grid item xs={12} md={4}>
         <Item><DataBox3 /></Item>
        </Grid>
      </Grid>
      <Box padding={3}></Box>
      <Grid container spacing={1} >
        <Grid item xs={12} md={6}>
          <Item><DataBoxWeather2 /></Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item><DataBoxWeather /></Item>
        </Grid>
      </Grid>
    </Box>
  );
}
