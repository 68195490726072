import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import Checkbox from '@mui/material/Checkbox';

import {supabase} from '../../lib/supabase';
import ResetUserPwdDialog from '../userManagement/ResetUserPwdDialog';
import DeleteUserDialog from '../userManagement/DeleteUserDialog';
import EditUserDrawer from '../userManagement/EditUserDrawer';
import DoneAllIcon from '@mui/icons-material/DoneAll';


export default function AllMistakes() {
  const [myPersonalEmanuelUserData, setMyPersonalEmanuelUserData] = useState(JSON.parse(localStorage.getItem("emanuelUser")) || null); 
  const [allMistakes, setMistakes] = useState([]);
  const [isResolved, setResolved] = useState(false);
  const [isHiden, setHiden] = useState(false);

  // useEffect(() => {
    
    // const emanuelUsersChanel = supabase.channel('custom-all-channel')
    // .on(
    //   'postgres_changes',
    //   { event: '*', schema: 'public', table: 'emanuelUsers' },
    //   (payload) => {
    //     console.log('Change received!', payload)
    //   }
    // )
    // .subscribe()
    // console.log('emanuelUsers', emanuelUsersChanel);

    // return emanuelUsersChanel.unsubscribe();
  // }, []);
  const handleResolvedChange = (event) => {     
    console.log('TBD: here will be initialization of handleResolvedChange checkbox change');
  }; 
  const handleHiddenChange = (event) => {     
    console.log('TBD: here will be initialization of handleResolvedChange checkbox change');
  }; 

  useEffect(() => {
    fetchMistakes();   
    
    const emanuelMistakesChanel = supabase.channel('custom-all-channel')
    .on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'logMistake' },
      (payload) => {
        fetchMistakes();   
      }
    )
    .subscribe()
    console.log('emanuelMistakes', emanuelMistakesChanel);

    //return emanuelUsersChanel.unsubscribe();
  }, []);

  async function fetchMistakes() {
    const { data, error } = await supabase.from('logMistake').select('user_findings, user_email, resolved, dropzone, salesforce_id, user_selected_category,is_private, id, created_at').eq('is_private','FALSE').eq('is_hidden', 'FALSE');
    if (error) {
      console.log("I`m the AllMistakes Component fetchMistakes Function returning error:")
      console.log(error);
      throw error;
    }
    else {      
      console.log('allMistakes: ',data);          
      setMistakes(data);
    }  
  }

  async function resolveMistake() {
    const { data, error } = await supabase.from('logMistake').select('user_findings, user_email, resolved, dropzone, salesforce_id, user_selected_category,is_private, id, created_at, hidden').eq('is_private','FALSE').eq('is_hidden', 'FALSE');
    if (error) {
      console.log("I`m the allMistakes Component resolveMistake Function returning error:")
      console.log(error);
      throw error;
    }
    else {      
      console.log('resolvet: ',data);              
    }  
  }
  return (     
    <TableContainer sx={{ maxWidth: '99%', textAlign: 'center' }} component={Paper}>
      <Table sx={{ minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>User Email</TableCell>
            <TableCell align="right">Salesforce Id</TableCell>            
            <TableCell align="right">User Findings</TableCell>
            <TableCell align="right">Dropzone replicate</TableCell>
            <TableCell align="right">Allready Resolved</TableCell>        
            <TableCell align="right">Created</TableCell>            
            {myPersonalEmanuelUserData.is_owner &&<TableCell align="right">Hide</TableCell>  }                                            
          </TableRow>
        </TableHead>
        <TableBody>
          {allMistakes.map((row) => (
            <TableRow
              key={row.AllMistakes}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <div className='row'><b>{row.user_email}</b></div>
              </TableCell>
              <TableCell align="right">{row.salesforce_id}</TableCell>
              <TableCell align="right">{row.user_findings}</TableCell>
              <TableCell align="right">{row.dropzone}</TableCell>
              <TableCell align="right">{row.resolved && <DoneAllIcon />}{!row.resolved }
              {myPersonalEmanuelUserData.is_owner &&<Checkbox id={'isResolved_' + row.Id} name={'isResolved_' + row.Id} checked={row.resolved} onClick={handleResolvedChange} />  }                
              </TableCell>
              <TableCell align="right">{row.created_at}</TableCell>
              {myPersonalEmanuelUserData.is_owner &&<TableCell align="right"><Checkbox id={'isHiden' + row.Id} name={'isHiden' + row.Id} checked={row.hidden} onClick={handleHiddenChange} /></TableCell>  }                                              
            </TableRow>
          ))}
        </TableBody>
      </Table>      
    </TableContainer>    
  );
}